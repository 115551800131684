import Vue from "vue";
import VueRouter from "vue-router";
// import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "publish",
  //   component: () => import("@/views/Publish"),
  // },
  {
    path: "/csmain",
    name: "csmain",
    component: () => import("@/views/CsMain"),
  },
  {
    path: "/csedit",
    name: "csedit",
    component: () => import("@/views/CsEdit"),
  },
  {
    path: "/csproject",
    name: "csproject",
    component: () => import("@/views/CsProject"),
  },
  {
    path: "/csfriend",
    name: "csfriend",
    component: () => import("@/views/CsFriend"),
  },
  {
    path: "/csmember",
    name: "csmember",
    component: () => import("@/views/CsMember"),
  },
  {
    path: "/cscontentlist",
    name: "cscontentlist",
    component: () => import("@/views/CsContentList"),
  },
  {
    path: "/cscontentview",
    name: "cscontentview",
    component: () => import("@/views/CsContentView"),
  },
  {
    path: "/csaddvideolist",
    name: "csaddvideolist",
    component: () => import("@/views/CsAddVideoList"),
  },
  {
    path: "/cslivelist",
    name: "cslivelist",
    component: () => import("@/views/CsLiveList"),
  },
  {
    path: "/csaddsubtitlelist",
    name: "csaddsubtitlelist",
    component: () => import("@/views/CsAddSubtitleList"),
  },
  {
    path: "/csaddsubtitleedit",
    name: "csaddsubtitleedit",
    component: () => import("@/views/CsAddSubTitleEdit"),
  },
  {
    path: "/csliveedit",
    name: "csliveedit",
    component: () => import("@/views/CsLiveEdit"),
  },
  {
    path: "/cslivelist",
    name: "cslivelist",
    component: () => import("@/views/CsLiveList"),
  },
  {
    path: "/cslivemeetingList",
    name: "cslivemeetinglist",
    component: () => import("@/views/CsLiveMeetingList"),
  },
  {
    path: "/csmeeting",
    name: "csmeeting",
    component: () => import("@/views/CsMeeting"),
  },
  {
    path: "/csmyprofile",
    name: "csmyprofile",
    component: () => import("@/views/CsMyProfile"),
  },
  {
    path: "/csfaq",
    name: "csfaq",
    component: () => import("@/views/CsFaq"),
  },
  {
    path: "/csinquiry",
    name: "csinquiry",
    component: () => import("@/views/CsInquiry"),
  },
  {
    path: "/csLogin",
    name: "cslogin",
    component: () => import("@/views/CsLogin"),
  },
  {
    path: "/cserror400",
    name: "cserror400",
    component: () => import("@/views/CsError400"),
  },
  {
    path: "/cserror500",
    name: "cserror500",
    component: () => import("@/views/CsError500"),
  },
  {
    path: "/csintro",
    name: "csintro",
    component: () => import("@/views/CsIntro"),
  },
  {
    path: "/cspolicy",
    name: "cspolicy",
    component: () => import("@/views/csPolicy"),
  },
  {
    path: "/csterms",
    name: "csterms",
    component: () => import("@/views/csTerms"),
  },
  {
    path: "/",
    name: "csindex",
    component: () => import("@/views/CsIndex"),
  },
  {
    path: "/cswait",
    name: "cswait",
    component: () => import("@/views/csWait"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
