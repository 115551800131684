import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import videojs from "video.js";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./assets/css/index.css";
import "vue-range-slider/dist/vue-range-slider.css";
import jQuery from "jquery";
global.jquery = jQuery;
global.$ = jQuery;
window.$ = window.jQuery = require("jquery");

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
// Vue.use(jQuery);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

global.$ = jQuery;
